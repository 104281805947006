import { useContext } from "react";
import { Input } from "../Input/Input";
import './search.scss';

import { ResultsContext } from "../../providers/ResultsProvider";

export function Search() {

    const { handleKeywordChange, keyword, setKeyword } = useContext(ResultsContext);


    const onKeywordChange = (e) => {
        setKeyword(e.target.value);
    }


    const search = async(e) =>{
        e.preventDefault();
        handleKeywordChange(keyword);
    }

    return (
        <form className="search" onSubmit={(e) => search(e)}>
            <div className="search__inner">
                <Input placeholder={'Keywords'} value={keyword} onChange={onKeywordChange}></Input>
                {/* <Input placeholder={'Postcode'} value={locationName} onChange={onLocationChange}></Input> */}
                <div className="f-r">
                    <button type="submit" className="brand">Search</button>
                </div>
            </div>
        </form>

    )
}