import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { SearchContext } from './SearchProvider';

export const ResultsContext = createContext();


const ResultsProvider = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateSearchData, updateIsLoading } = useContext(SearchContext);
  const [keyword, setKeyword] = useState(searchParams.get('keywords') || '');
  const [pageNumber, setPageNumber] = useState(searchParams.get('pageNumber') || '');
  const [searchUrl, setSearchUrl] = useState('');

  const handleKeywordChange = (keyword) => {

    setKeyword(keyword);
    const params = { keywords: keyword };
    setSearchParams(params);

    const updatedSearchParams = new URLSearchParams(params);
    updatedSearchParams.delete('pageNumber');

    navigate({ search: updatedSearchParams.toString() });
  };

  const handlePageNumberChange = async (e, number) => {
    updateIsLoading(true);
    e.preventDefault();
    setPageNumber(number);
    const params = { pageNumber: number };
    updateURL(params);
  }


  const updateURL = (newParams) => {
    setSearchParams(prevParams => {
      return { ...prevParams, ...newParams };
    });

    const updatedSearchParams = new URLSearchParams(searchParams);
    for (const [key, value] of Object.entries(newParams)) {
      updatedSearchParams.set(key, value);
    }

    navigate({ search: updatedSearchParams.toString() });
  };

  const onSearch = async () => {

    const updatedSearchParams = new URLSearchParams(searchParams);
    const url = `https://api.brontejobs.com/api/roles/search?${updatedSearchParams.toString()}`;

    if (searchUrl === url) {
      return;
    }

    updateIsLoading(true);
    await fetch(url).then(p => p.json()).then(o => {
      updateSearchData(o);
      updateIsLoading(false);
      setSearchUrl(url);

      const element = document.querySelector(".hero__narrow");
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      await onSearch();
    };

    if (location.search !== '') {
      fetchData();
    }
  }, [location.search, searchParams]);

  return (
    <ResultsContext.Provider value={{ handleKeywordChange, handlePageNumberChange, onSearch, keyword, setKeyword }}>
      {props.children}
    </ResultsContext.Provider>
  );

}

export default ResultsProvider;